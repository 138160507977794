import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Icon, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Diversity1,
  Diversity3,
  AttachMoney,
  People,
} from "@mui/icons-material";
import { getData } from "../services/Request";

const useStyles = makeStyles({
  card: {
    position: "relative",
    minWidth: "275px",
    borderRadius: "10px",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
    overflow: "visible",
    "&:hover": {
      boxShadow: "0 6px 10px rgba(0, 0, 0, 0.3)",
    },
  },
  iconContainer: {
    position: "absolute",
    top: "-20px",
    left: "20px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconStyle: {
    color: "#fff",
    fontSize: "2rem",
  },
  cardContent: {
    textAlign: "right",
    paddingTop: "20px",
  },
  categoryText: {
    fontSize: "0.9rem",
    color: "#999",
  },
  valueText: {
    fontSize: "2rem",
    fontWeight: "bold",
  },
});

const Cards = () => {
  const classes = useStyles();
  const [cards, setCards] = useState([
    {
      item: 1,
      icon: <People />,
      category: "Users",
      key: "Users",
      value: 0,
      currentCount: 0,
      gradient: "linear-gradient(60deg, #9c27b0, #7b1fa2)",
    },
    {
      item: 2,
      icon: <Diversity1 />,
      category: "Total Groups",
      key: "TotalGroups",
      value: 0,
      currentCount: 0,
      gradient: "linear-gradient(60deg, #ffa726, #fb8c00)",
    },
    {
      item: 3,
      icon: <Diversity3 />,
      category: "Active Groups",
      key: "ActiveGroups",
      value: 0,
      currentCount: 0,
      gradient: "linear-gradient(60deg, #66cdaa, #2e8b57)",
    },
    {
      item: 4,
      icon: <AttachMoney />,
      category: "Amount",
      key: "Amount",
      value: 0,
      currentCount: 0,
      gradient: "linear-gradient(60deg, #ef5350, #e53935)",
    },
  ]);

  useEffect(() => {
    getCounts();
  }, []);

  const getCounts = async () => {
    try {
      const response = await getData("auth/getAllCounts");
      setCards((prevCards) =>
        prevCards.map((card) => ({
          ...card,
          value: response[card.key] || 0, // Map API response to the correct card key
        }))
      );
      animateCounts();
    } catch (error) {
      console.error(error);
    }
  };

  const animateCounts = () => {
    setCards((prevCards) =>
      prevCards.map((card) => {
        const step = Math.ceil(card.value / 30);
        let count = 0;

        const interval = setInterval(() => {
          count += step;
          if (count >= card.value) {
            setCards((updatedCards) =>
              updatedCards.map((c) =>
                c.item === card.item ? { ...c, currentCount: card.value } : c
              )
            );
            clearInterval(interval);
          } else {
            setCards((updatedCards) =>
              updatedCards.map((c) =>
                c.item === card.item ? { ...c, currentCount: count } : c
              )
            );
          }
        }, 50);

        return card;
      })
    );
  };

  return (
    <Box
      sx={{
        background: "#c47ceb21",
        height: "100vh",
        marginLeft: "-5px",
      }}
    >
      <Grid container spacing={2} pl={2} pr={2} pt={2}>
        {cards.map((item, index) => (
          <Grid item xs={12} sm={4} lg={4} key={index} mt={2}>
            <Card className={classes.card}>
              <div
                className={classes.iconContainer}
                style={{ background: item.gradient }}
              >
                <Icon className={classes.iconStyle}>{item.icon}</Icon>
              </div>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.categoryText} variant="body2">
                  {item.category}
                </Typography>
                <Typography className={classes.valueText}>
                  {item.currentCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Cards;
