import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Checkbox,
  Typography,
  Container,
  Box,
  Alert,
} from "@mui/material";
import Logo from "../assets/Ajooo_Logo.png";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { postData } from "../services/Request";

const theme = createTheme();

const Login = () => {
  const nav = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loader, setLoder] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    var details = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    if (details.email.trim() === "") {
      setAlertMessage("Input your email");
      setSeverity("warning");
    } else if (details.password.trim() === "") {
      setAlertMessage("Enter your Passwords");
      setSeverity("warning");
    } else {
      setLoder(true);
      setAlertMessage("");

      postData("auth/signin", {
        email: email,
        password: password,
        deviceId: "PC12DESK74",
      })
        .then((response) => {
          if (response.accessToken != "") {
            localStorage.setItem(
              "user",
              JSON.stringify({ usertype: response.data.userType })
            );
            localStorage.setItem("token", response.accessToken);
            setAlertMessage("Logging you in......");
            setSeverity("success");
            nav("/dashboard");
          }
        })
        .catch((error) => {
          console.error(error);
          setAlertMessage("User & Password incorrect");
          setSeverity("warning");
        })
        .finally(() => {
          setLoder(false);
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        style={{
          backgroundSize: "cover",
          minHeight: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#fff",
              padding: "24px",
              borderRadius: "12px",
              boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
              border: "1px solid #52057B", // Corrected border
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#52057B",
                borderRadius: "12px",
                width: "100%",
                paddingTop: "20px",
                paddingBottom: "20px",
                position: "relative",
                top: "-44px",
              }}
            >
              <img src={Logo} alt="logo" />
              <Typography component="h1" variant="h5" sx={{ color: "#ffff" }}>
                Sign in
              </Typography>
            </Box>
            {alertMessage && (
              <Alert variant="filled" severity={severity}>
                {alertMessage}
              </Alert>
            )}
            <Box
              component="form"
              onSubmit={handleLogin}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#52057B", // Focus border color
                    },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#52057B", // Focus label color
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#52057B", // Focus border color
                    },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#52057B", // Focus label color
                  },
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  value={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  sx={{
                    color: "#52057B", // Unchecked color
                    "&.Mui-checked": {
                      color: "#52057B", // Checked color
                    },
                  }}
                />
                <Typography>Remember me</Typography>
              </Box>
              {loader === false && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2,
                    padding: "10px",
                    backgroundColor: "#52057B",
                  }}
                >
                  Sign In
                </Button>
              )}
              {loader === true && (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2,
                    padding: "10px",
                    backgroundColor: "#52057B",
                  }}
                >
                  <CircularProgress />
                </Button>
              )}
            </Box>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default Login;
